<template>
<hbox class="carrousel flex-wrap">
  <div class="info-container">
    <h1 class="alternance title"><span>Un soutien à l’emploi</span><br/> du spectacle vivant</h1>

    <p class="text" >Le Gip Cafés Cultures assure la gestion de deux fonds dédiés au soutien à l’emploi artistique dans
      les lieux de proximité (cafés, associations d’animation de quartier ou village, de parents d’élèves, commerces, etc.).
      Nous contribuons à encourager et pérenniser l’emploi des artistes et techniciens du spectacle vivant dans ces espaces
      de vie essentiels à la dynamique culturelle de nos territoires.</p>

    <hbox class="btn-container">
      <BtnAction go-to="Simulateur" titre="FAIRE UNE SIMULATION"/>
      <BtnAction to="Presentation" id="button-decouvrir-gip" type="action" titre="DÉCOUVRIR LE GIP"/>
  </hbox>
  </div>

  <div class="carrousel-container">
    <Sphere  top="-40" left="0" couleur="grey" taille="213"/>
    <b-carousel
        class="trip"
        ref="carousel"
        v-model="index"
        style="text-shadow: 0px 0px 2px #000"
        :interval="interval"
        >

      <div class="gestion-image">
      <b-carousel-slide v-for="(img, index) in this.images" :key="index"
                        >
        <template #img>
         <img v-on:click="next" :src="'images/' + img.fichier" :title="img.alt || img.fichier" :alt="img.alt || img.fichier">

      </template>
      </b-carousel-slide>
    </div>

    </b-carousel>
    <div class="sphere-container-carousel">

    <Sphere  right="0" bottom="-60" taille="213"/>
    </div>
      <vbox>
    <div  class="arrow-container hidden-small">
      <div class="arrow" @click="prev">
      <FlecheLogo rotate="180" color="#C22292"> </FlecheLogo>
      </div>

      <p class="pagination">{{index +1}}/{{images.length}}</p>
      <div class="arrow" @click="next">
        <FlecheLogo @click="next" color="#C22292" />
        </div>
    </div>
      </vbox>

  </div>
</hbox>
</template>

<script>
import BtnAction from "./BtnAction";
import Sphere from "./Sphere";
import FlecheLogo from "../Icons/FlecheLogo";
import {BCarousel} from "bootstrap-vue";
import {BCarouselSlide} from "bootstrap-vue";
import GipMixin from "./GipMixin";
import kit from "../../kit/kit";
export default {
  mixins: [GipMixin],

  name: "Carrousel",
  components: {BtnAction,Sphere,FlecheLogo,BCarousel,BCarouselSlide},
  data () {
    return {
      index:0,
      images: null,
      // currentImg: null,//require('../../assets/image/carrousel_1.jpg'),
      interval:5000,
      site: null
    }
  },
 
  mounted() {

    this.images = this.state.site.images

  },
  methods: {

  
    prev() {
      this.$refs.slides = this.images
      this.$refs.carousel.prev()
      this.interval = 0;
    },
    next() {
      this.$refs.carousel.next()
      this.$refs.carousel.pause();
      this.interval = 0;


    },
    stop() {
      this.interval = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/kit.scss";

.btn-container {
  margin-top: 55px;
  float: left;
  &> *{
    margin-right: 20px;
  }
}

.pagination{
  user-select: none;
}

.arrow-container {
  margin-top: 25px;
  float: left;
  display: block;
  &>*{
    margin-right: 30px;
  }
  p{
    display: inline-block;
  }
}

.carrousel{
  padding-top: 63px ;
  z-index: 5;
}



.sphere-container-carousel {
  position: relative;

  max-width: 675px;
}
.info-container {
  display: inline-block;
  vertical-align: top;
  padding: 0 36px 0 36px;
  max-width: 454px;
  text-align: left;
  .text{
    font-size: 16px;
    font-weight: 400;
    margin-top:34px
  }
  .title{
    margin-top: 20px;
  }
}

@media (max-width: $size-small) {

  .info-container {
    min-width: 0;
    max-width: inherit;
  }

  .btn-container {
    flex-direction: column;


  }
  #button-decouvrir-gip  .action {
    text-align: left !important;
    padding-left: 0 !important;
  }
}

.carrousel-container{

  position: relative;
  width: 50%;
  margin-top: 40px;
  flex-grow: 1;
}
.trip {
  z-index: 5;
}

.gestion-image{
max-height: 454px;
display: block;
overflow: hidden;
border-radius: 5px 0px 0px 5px;
}

.arrow {
  display: inline-block;
  cursor: pointer;
}


</style>