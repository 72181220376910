<template>
  <div class="gip-bg-default">

  <div class="gip-max-width gip-mobile-padding">
    <div class="head">
      <h1 class="alternance ">Le Gip Cafés Cultures <br/> <span>a été créé en avril 2015</span></h1>
      <p class="preambule">
        Le Groupement d’Intérêt Public ( Gip ) est la structure juridique adoptée <br/>
        pour ce dispositif sans précédent. Il permet à un membre de droit public <br/>
        de mettre en commun des moyens avec d’autres partenaires, de droit<br/>
        public ou privé, dans l’objectif d’assurer une mission d’intérêt général.
      </p>
    </div>
    </div>
    <Carte/>
    <div class="gip-max-width">

    <div class="objectifs">
      <div class="overlay">
      <Diviseur couleur="white" titre="OBJECTIFS"/>
      <h2 class="alternance white"> <span>Le Gip Cafés Cultures</span><br/> pour soutenir l’emploi artistique</h2>
      <hbox class="justify-center">
      <p class="preambule" >Le Gip a pour objet le soutien à l’emploi d’artistes et de techniciens
        du spectacle vivant dans les lieux de proximité. Cette activité d’intérêt général contribue à soutenir les pratiques culturelles,
        développer l’attractivité des territoires et travailler à un maillage riche et diversifié.</p>
        </hbox>



      <div class="btn-container">
      <BtnAction type="white"  to="FondsAide" titre="DÉCOUVRIR LES FONDS D’AIDE"/>
      </div>
      </div>
      <Sphere top="-60" right="-40" taille="231"/>
    </div>
    <PlusSurNous/>
    <DernierMembre/>
  </div>
    </div>

</template>

<script>
import Carte from "../ui/Carte";
import Sphere from "../ui/Sphere";
import Diviseur from "../ui/Diviseur";
import PlusSurNous from "../ui/PlusSurNous";
import DernierMembre from "../ui/DernierMembre";
import BtnAction from "../ui/BtnAction";
import GipMixin from "../ui/GipMixin";
export default {
  mixins: [GipMixin],
  name: "Presentation",
  components: {Carte,Sphere,PlusSurNous,DernierMembre,Diviseur,BtnAction}
}
</script>

<style lang="scss" scoped>
@import "../../assets/gip.scss";

.overlay{
  position: relative;
  z-index: 5;
  padding: 20px 34px 20px 34px;
  background: var(--gip-tint-color);
  border-radius: 5px;


}



.head{
  text-align: center;
  h1{
    padding-top: 100px;
  }
  p{
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.objectifs{
  text-align: center;
  color: white;
  position: relative;
  margin: 0 auto;
  overflow-x: hidden;
  margin-top: 164px;
  max-width: 900px;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 5px;
  .btn-container{
    text-align: right;
  }
  p{
    text-align: left;
    margin-top: 44px ;

    color: white;

  }
  .text .btn{
    text-align: left;
    font-size: 15px;
  }
  .btn{
    text-align: right;
    width: 100%;
    display: contents;
  }
}

@media all and (max-width: 960px) {
  .img{

    max-width: 100%;
  }
}


</style>