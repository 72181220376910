import XMLWriter from '../misc/XMLWriter.js'
import CQuery from './CQuery.js'
import kit from '../kit.js'
import CError from './CError'
import CModel from './CModel'
import CClass from './CClass'
import CField from './CField'
import CLinkField from './field/CLinkField'
import CRelationField from './field/CRelationField'
import CObjectSet from './CObjectSet'
import CObject from './CObject'
import Utilities from '../misc/Utilities.js'
import $ from 'jquery'
import Log from "../misc/Log";

/**
 * The class that defines services availables on the server
 * PENDING should extends CDataProvider
 * @class com.omniapp.kit.model.CServicesProvider
 */
var CServicesProvider = {

    /**
     * delete an object
     * service : submit
     * PENDING NOT USED
     *
     * <transaction>
     *   <delete cclass="Toto" id="titi"/>
     * </transaction>
     * @param cclass
     * @param id
     */
    remove: function (cclass, id) {

        var xml = new XMLWriter();
        xml.open("transaction");
        xml.add("delete", {'id': id, 'cclass': cclass});
        xml.close("transaction");
        alert("transaction=" + xml.toString());
        kit.log.debug(xml.toString());
        // var transaction = CServicesProvider.XML_HEADER + "<transaction><delete cclass=\"" + cclass
        // + "\" id=\"" + id + "\"/></transaction>";
        // Log.debug("transaction=" + transaction);
        // CServicesProvider.transaction(xml.toString());
    },

    /**
     * delete an object
     */
    'delete': function (object, onSuccess, onFail) {
        var xml = new XMLWriter();
        xml.open("transaction");
        xml.add("delete", {'id': object.id, 'cclass': object.cclass});
        xml.close("transaction");
        //alert("transaction=" + xml.toString());
        CServicesProvider.service("submit", xml.toString(), function () {
            kit.message.send(object.cclass, {'type': 'delete', 'object': object});
            if (onSuccess)
                onSuccess(object);
        }, onFail);
        //Log.debug(xml.toString());
    },

    /**
     * call the submit service, either for create or update
     */
    create: function (cobject, onSuccess, onFail) {
        var xml = CServicesProvider.write(cobject);
        CServicesProvider.service("submit", xml, function (data) {
            var node = Utilities.children(data.firstChild)[0];
            var object = CObject.fromXML(node);
            kit.message.send(object.cclass, {'type': 'create', 'object': object});
            if (onSuccess)
                onSuccess(object);
        }, onFail);
    },

    /**
     * call the submit service, either for create or update
     * PENDING REMOVE ME, should use create or update
     */
    submit: function (cobject, onSuccess, onFail) {
        var xml = CServicesProvider.write(cobject);
        // alert("submit", xml=" + xml);
        CServicesProvider.service("submit", xml, onSuccess, onFail);
    },
    
    /**
     * 
     * call creer-compte servive
     */
    creerCompte: function (cobject, onSuccess, onFail) {
        var xml = CServicesProvider.write(cobject);
        CServicesProvider.service("creer-compte", xml, onSuccess, onFail);
    },

    /**
     * call the check service
     */
    check: function (cobject, onSuccess, onFail) {
        var xml = CServicesProvider.write(cobject);
        // alert("check, xml=" + xml);
        CServicesProvider.service("check", xml, onSuccess, onFail);
    },

    /**
     * Doit remplacer à terme services pour être plus générique. Permet également
     * de se rapprocher d'axios
     * @param url
     * @param config json object for config, containing
     * data : data to send
     * params : form params
     * onSuccess : method called on success
     * onProgress : method called on progress
     * onError : method called on error
     */
     service2: async function (url, config) {
        var fullUrl = kit.servicesUrl + "/" + url;
        config = typeof config === 'undefined' ? {} : config

        // let result = await ($.ajax({
            $.ajax({
            url: fullUrl,
            // processData: true,
            // contentType : 'application/x-www-form-urlencoded',
            processData: config.params ? true : false,
            contentType: config.params ? 'application/x-www-form-urlencoded' : false,
            xhrFields: {
                withCredentials: true
            },
            data: config.params ? config.params : config.data,
            async: true,
            type: config.method ? config.method : "POST",
            xhr: () => {
                var xhr = new window.XMLHttpRequest();
                //Download progress
                if (config.onProgress) {
                    xhr.addEventListener("progress", (evt) => {
                        config.onProgress(evt)
                        // if (evt.lengthComputable) {
                        //     var percentComplete = evt.loaded / evt.total;
                        //     progressElem.html(Math.round(percentComplete * 100) + "%");
                        // }
                    }, false);
                }
                return xhr;
            },
            // complete : function (jqXHR, textStatus) {
            // },
            success: (responseData, textStatus, jqXHR) => {
                if (config.onSuccess)
                    config.onSuccess(responseData, jqXHR);
            },
            error: (jQxhr, textStatus, HTTPError) => {
                kit.log.debug("response=" + jQxhr.responseText);
                var error = CError.parse(jQxhr.responseText);
                if (error && config.onError)
                    config.onError(error);
            }
        });
        // if (config.onSuccess)
        //     config.onSuccess(result.responseText)
    },

    /**
     * run a service on the server
     * @param url url
     * @param data body
     * @param onSuccess : function called when request is successfull. is given request content as param
     * @param onError : function called when error occurs. is given a CError as param
     * @param onProgress : function called on progress, evt as argument
     */
    service: async function (url, data, onSuccess, onError, onProgress) {

        // pour que data passe en parametres post il faut
        // processData: true
        // contentType : 'application/x-www-form-urlencoded'
        // et sinon il faut :
        // processData: false
        // contentType: false

        var fullUrl = kit.servicesUrl + "/" + url;
        kit.log.log("url=" + fullUrl);
        const result = await $.ajax({
            url: fullUrl,
            // processData: true,
            // contentType : 'application/x-www-form-urlencoded',
            processData: false,
            contentType: false,
            xhrFields: {
                withCredentials: true
            },
            data: data,
            async: true,
            type: "POST",
            xhr: function () {
                var xhr = new window.XMLHttpRequest();
                // Download progress
                if (onProgress) {
                    xhr.addEventListener("progress", function (evt) {
                        onProgress(evt)
                        // if (evt.lengthComputable) {
                        //     var percentComplete = evt.loaded / evt.total;
                        //     progressElem.html(Math.round(percentComplete * 100) + "%");
                        // }
                    }, false);
                }
                return xhr;
            },
            // complete : function (jqXHR, textStatus) {
            // },
            // done, fail
            success:  function (responseData, textStatus, jqXHR) {
                if (onSuccess)
                     onSuccess(responseData, jqXHR);
            },
            error: function (jQxhr, textStatus, HTTPError) {
                kit.log.debug("response=" + jQxhr.responseText);
                var error;
                if(jQxhr.responseText) {
                    error = CError.parse(jQxhr.responseText);
                } else {
                    error = "Une erreur s'est produite"
                }
                if (error && onError)
                    onError(error);
            }
        })
            // new stuff
        //     .then(async function(responseData, textStatus, jqXHR) {
        //         if (onSuccess)
        //             await onSuccess(responseData, jqXHR);
        //     })
        //     .fail(function(jQxhr, textStatus, HTTPError) {
        //         kit.log.debug("response=" + jQxhr.responseText);
        //         var error = CError.parse(jQxhr.responseText);
        //         if (error && onError)
        //             onError(error);
        // });
        return result
    },

    /**
     * run a transaction on the server
     */
    transaction: function (transaction) {

        $.ajax({
            url: "/services/submit",
            processData: false,
            data: transaction,
            async: true,
            dataType: "xml",
            type: "POST",
            success: function (data) {
                Log.debug("success");
            },
            error: function (jQxhr, textStatus, HTTPError) {
                Log.debug("error");
//					, status=" + textStatus + " httpError=" + HTTPError
//							+ " data=" jQxhr.textContent());
            }
        });
    },

    /**
     * call query service for a given id
     * @param cclass CClass object or CClass string id
     * @param id string id of object to get
     * @param onSuccess if object is found get this object as param
     * @param onError is called if no object found
     * @param depth query depth
     */
    get: function (cclass, id, onSuccess, onError, depth) {
        if (!(cclass instanceof CClass))
            cclass = kit.model.cclass(cclass);
        var url = "query?cclass=" + cclass.id() + "&id=" + id + "&json";
        if (!(depth === undefined))
            url += "&depth=" + depth;
        CServicesProvider.service(url, null,
            function (data) {
                // alert
                // var object = CObject.fromXML(data.firstChild);
                // onSuccess(object);
                onSuccess(data)
            },
            onError);
    },

    /**
     * call query service
     * we want :
     * query(aCClass: String)
     * query(aCClass : cclass)
     * query({cclass:aCClass, filter:xx, limit: 1}
     */
    query: async function (cclass, onSuccess, onError) {

        var query = null;
        // string or cclass
        if ((typeof cclass === 'string') || (typeof cclass == CClass))
            query = new CQuery({cclass: cclass})
        // else json configuring a query
        else
            query = new CQuery(cclass)
        // var query = new CQuery({cclass:cclass, filter: filter});
        // if (depth !== undefined)
        // 	query.depth = depth;
        await CServicesProvider.query2(query, onSuccess, onError);
    },

    /**
     * Call query service using a CQuery
     * @param query
     * @param onSuccess
     * @param onError
     */
    query2: async function (query, onSuccess, onError) {
        // we can force service change
        var service = query.service ? query.service : 'query';
        var url = service + "?cclass=" + query.cclass.id();
        if (query.depth && query.depth > -1)
            url += "&depth=" + query.depth;
        if (query.depths)
            url += "&depths=" + encodeURIComponent(JSON.stringify(query.depths));
        if (query.id)
            url += "&id=" + query.id;
        if (query.match)
            url += "&match=" + query.match;
        if (query.limit !== -1)
            url += "&limit=" + query.limit;
        if (query.limitOffset !== 0)
            url += "&offset=" + query.limitOffset;
        if (query.orderField !== null)
            url += "&order=" + query.orderField.id();
        if (query.reverse === true)
            url += "&reverse=true";
        if (query.json === true)
            url += "&json=true";
        await CServicesProvider.service(url, query.filter ? query.filter.toXml() : null, onSuccess, onError);
    },

    /**
     * call data service
     */
    data: function (cclass, field, id, onSuccess, onError) {
        var url = "data?cclass=" + cclass + "&field=" + field + "&id=" + id;
        CServicesProvider.service(url, null, onSuccess, onError);
    },

    /**
     * build xml rep of a CObject
     * @param cclass object cclass id
     * @param cobject fieldId/value array
     */
    write: function (cobject) {

        var xml = new XMLWriter();
        xml.open("transaction");

        if (cobject.id === null)
            xml.open("create", {'cclass': cobject.cclass});
        else
            xml.open("update", {'cclass': cobject.cclass, 'id': cobject.id});

        // CServicesProvider.buildCObjectXML(xml, cobject.cclass, cobject);
        CServicesProvider.writeObject(xml, cobject);
        if (cobject.id === null)
            xml.close("create");
        else
            xml.close("update");
        xml.close("transaction");
        return xml.toString();
    },

    //Camping de saint disdille
    encodeHTMLEntities : function(text) {
        var textArea = document.createElement('textarea');
        textArea.innerText = text;
        
        return textArea.innerHTML.replaceAll('<br>','<br/>');
    },

    /**
     * write a CObject as xml
     * if they are newValues use them else write values
     * @param xml XmlWriter
     * @param cobject object to write
     */
    writeObject: function (xml, cobject) {

        if (cobject.id === null)
            xml.open("cobject", {"cclass": cobject.cclass});
        else
            xml.open("cobject", {"cclass": cobject.cclass, "id": cobject.id});

        // iterate over fields
        var useNew = cobject.newValues !== null;
        var values = useNew ? cobject.newValues : cobject.data;

        for (var field in values) {
            var cfield = kit.model.cclass(cobject.cclass).field(field);
            if (cfield instanceof CLinkField)
                continue;
            if ((cfield instanceof CRelationField) && (cfield.relationType() === 'ASSOCIATION') && !cfield.multiple()) {
                if (cfield.multiple()) {
                    alert('ecrit une association multiple')
                } else {
                    var val = useNew ? cobject.newValue(field) : cobject.value(field);
                    var svalue = cfield.stringValue(val);
                    xml.add("value", {"cfield": field}, svalue);
                }
            } else {
                var value = useNew ? cobject.newValue(field) : cobject.value(field);
                // multiple composition
                if (value instanceof CObjectSet) {
                    //  Log.log('write cobjectset');
                    xml.open("value", {"cfield": field});
                    xml.open("cobjectset", {"cclass": value.cclass});
                    for (var i = 0; i < value.size(); i++)
                        CServicesProvider.writeObject(xml, value.get(i));
                    xml.close("cobjectset");
                    xml.close("value");
                }
                // single composition
                else if (value instanceof CObject) {
                    //  Log.log('write cobject');
                    xml.open("value", {"cfield": field});
                    CServicesProvider.writeObject(xml, value);
                    xml.close("value");
                }

                // normal value
                else {
                    //   Log.log('write value, field=' + field + " value=" + value);
                    // if (value) {
                   
                    var cclass = kit.model.cclass(cobject.cclass);
                    let str = cclass.field(field).stringValue(value)
                    if (str !== null)
                        str = this.encodeHTMLEntities(str)

                    xml.add("value", {"cfield": field}, str);
                    // }
                }
            }
        }

        xml.close("cobject");
    },
    /**
     * 
     * @param url url du service
     * 
     * renvoie le json de la réponse
     * si erreur renvoie la reponse de la requête dans une exception (error.response)
     */
    serviceGet: async function(url) {
        var fullUrl = kit.servicesUrl + "/" + url;
        const response = await fetch(fullUrl)
        if (response.ok) {
            const json = await response.json()
            return json
        } else {
            let err =  new Error('serviceGet: Error receiving datas')
            err.response = response
            throw err
        } 
    }
    // write a CObject as xml
    // buildCObjectXML : function (xml, cclass, cobject ) {
// 
    // // write a relation
    // // var relationXML = function(xml, relation) {
// // 
    // // xml.open("value", {"cfield":relation.field});
// //           
    // // if (relation.multiple)
    // // xml.open("cobjectset", {"cclass":relation.targetClass});
// // 
    // // for (var i = 0; i < relation.cobjects.length; i++)
    // // CServicesProvider.buildCObjectXML(xml, relation.targetClass, relation.cobjects[i]);
// // 
    // // if (relation.multiple)
    // // xml.close("cobjectset");
// //             
    // // xml.close("value");
// // 
    // // return xml;
// // 
    // // };
//         
    // if (cobject.id == null)
    // xml.open("cobject", {"cclass":cclass}, true, false, true);
    // else
    // xml.open("cobject", {"cclass":cclass, "id":cobject.id}, true, false, true);
// 
    // // PENDING correction de merde, cobject est soit un cobject, soit data direct
    // // pour les relations
    // var data = cobject.data;
    // // alert('typeof cobject=' + typeof cobject);
//         
//         
    // for (var i in data ) {
    // Log.debug('data type=' + typeof data[i]);
    // if ( typeof data[i] !== 'object') {
    // xml.add("value", {"cfield":i}, data[i]);
    // }
    // else {
    // relationXML(xml, data[i]);
    // }
    // }
    // xml.close("cobject");
    // return xml.toString();
    // }

};

export default CServicesProvider
