<template>
  <div id="mentions" class="gip-max-width">
   <!-- mentions légales -->
  <h2>Éditeur</h2>
    <pre> Le site « gipcafescultures.fr » est édité par le Gip Cafés Cultures.

Direction de la publication
GIP Cafés Cultures
2 impasse Girardon, 75018 Paris
contact@gipcafescultures.fr
01 42 58 98 90
  </pre>
  <h2>Création et conception graphique & rédactionnelle</h2>
    <pre>
      Agence MA3
      Agence Sentiments distingués

    </pre>
  <h2>Développements et hébergement</h2>
    <pre>
    OmniApp
    7 rue des Caillots 93100 Montreuil
    01 86 86 03 03
    contact@omniapp.fr
    <a href="https://omniapp.fr">omniapp.fr</a>
    </pre>
  <h2>Cookies</h2>
    <pre>Un cookie est un petit fichier texte sauvegardé sur votre ordinateur ou votre appareil mobile puis récupéré lors de vos visites ultérieures. Gip Cafés Cultures utilise des cookies pour que votre visite soit plus simple et agréable. Nous n’utilisons pas les cookies pour conserver des informations personnelles, ni pour divulguer des informations à des tiers.

Il existe deux types de cookies, permanents et temporaires (cookies de session). Les cookies permanents sont stockés sous forme de fichier sur votre ordinateur ou votre appareil mobile pendant une durée ne dépassant pas 12 mois. Les cookies de session sont stockés de manière temporaire et disparaissent dès la fermeture de votre session de navigation.

Vous pouvez facilement effacer les cookies de votre ordinateur ou de votre appareil mobile à l’aide de votre navigateur. Pour consulter les instructions relatives à la gestion et à la suppression des cookies, merci de vous reporter à la rubrique d’aide de votre navigateur. Selon vos préférences, vous pouvez désactiver les cookies ou demander à être averti à chaque fois qu’un nouveau cookie est transmis à votre ordinateur ou votre appareil mobile. Veuillez noter que si vous désactivez les cookies, vous ne pourrez plus profiter de toutes les fonctionnalités.
    </pre>
  <h2>Cookies tiers</h2>
    <pre>Nous utilisons des cookies tiers afin de recueillir des statistiques sous forme agrégée dans des outils d’analyse tel que Google Analytics. Ces cookies peuvent être permanents ou temporaires (cookies de session). Les cookies permanents sont stockés sur votre ordinateur ou votre appareil mobile pendant une durée ne dépassant pas 24 mois.
    </pre>
  <h2> Données personnelles</h2>
  <pre>Les informations que le Gip Cafés Cultures est amené à recueillir proviennent :
- soit de l'inscription volontaire d'une adresse e-mail de votre part vous permettant de recevoir des informations;
- soit de la saisie complète de vos coordonnées par vos soins à l'occasion de la création de votre compte employeur.
Ces informations permettent au Gip Cafés Cultures de traiter vos demandes d’aides.

Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, les données personnelles collectées par le Gip Cafés Cultures ont fait l’objet d’une déclaration auprès de la CNIL, enregistrée sous le numéro 1895128.
Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant par courrier postal au Gip Cafés Cultures - 2 impasse Girardon, 75018 Paris
propriété intelectuelle
  </pre>
  <h2>Crédit & Droit d’auteur</h2>
    <pre>
 L'ensemble des contenus (photos, textes, vidéos, sons...) consultables sur ce site demeure la propriété exclusive de Gip Cafés Cultures et/ou de ses partenaires et relève de la législation française sur le droit d'auteur et la propriété intellectuelle.

 Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques. Toute extraction et/ou reproduction d'une partie substantielle des informations diffusées sur le site est interdite sans l'autorisation préalable de Gip Cafés Cultures et constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le code de la Propriété Intellectuelle précise qu'il faut citer la source ou demander l'autorisation de l'auteur des images.

 Il ne joue que dans le cadre du respect du droit d'auteur. En tant que création de l'esprit, les images (même les copies d'écran) sont donc protégées par le droit d'auteur (art. L112-2-9 du Code de Propriété Intellectuelle). Le fait de reproduire ou de représenter, sans autorisation, des images, textes ou documents est punissable sur le plan civil par une condamnation à des dommages et intérêts. Ces dommages et intérêts sont calculés au cas par cas. Cela dépend du manque à gagner de la victime, du préjudice subi dû à la dénaturation de la façon dont est présenté le site et des répercussions qu'a pu en avoir l'auteur du site, de la façon dont l'image a été mise en avant et de celle qu'elle a pu rapporter éventuellement en termes financiers au concepteur.
    </pre>
  <h2>Crédit Photographiques</h2>
    <pre>
    Julien Judd
    Fabien Roth
    Ville de Nantes
    RBK records
    Guy Yoyote Husson
    Pierre Wetzel / Toums
    Yves Petit
    </pre>
  </div>
</template>

<script>


export default {
  name: "MentionsLegales",
  data() {
    return {
      isMenuOpen: false,
    }
  },
  computed: {

  },
  props: {

  },



  methods: {}
}
</script>

<style lang="scss" scoped>

#mentions {
  padding-top:  4em;
  background: var(--gip-white-color);
  padding-left: 2em;
}

pre {
  color: inherit;
  background: inherit;
  white-space:pre-wrap;
font:inherit;
}

</style>